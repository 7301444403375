import React, { useEffect, useState } from 'react'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

import Icon from '../Icon/Icon'
import Button from '../Button/Button'
import ButtonComponent from '../Button/Button'
import Modal from 'react-modal'
import zIndex from '@material-ui/core/styles/zIndex'

const ModalComponent = props => {
	const [modalIsOpen, setIsOpen] = useState(false)

	const isBrowser = typeof window !== 'undefined'
	let popUpOpenFromStorage = null

	const today = new Date().setDate(new Date().getDate())

	const shouldShowModal = today < new Date('1/2/2025').setDate(new Date('1/2/2025').getDate())

	if (isBrowser) {
		// eslint-disable-next-line no-undef
		popUpOpenFromStorage = window.localStorage.getItem('modalOpen')
	}

	useEffect(() => {
		if (!modalIsOpen && popUpOpenFromStorage !== 'false' && shouldShowModal) {
			setTimeout(() => {
				setIsOpen(true)
			}, 3000)
		}
	}, [modalIsOpen])

	const closeModal = () => {
		if (isBrowser) {
			// eslint-disable-next-line no-undef
			window.localStorage.setItem('modalOpen', false)
		}
		setIsOpen(false)
	}

	const redirectContact = () => {
		setIsOpen(false)
		if (isBrowser) {
			// eslint-disable-next-line no-undef
			window.localStorage.setItem('modalOpen', false)
			// eslint-disable-next-line no-undef
			window.location.href = '/contact'
		}
	}

	const customStyles = {
		overlay: {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'rgb(47 47 47 / 75%)',
			zIndex: 99999
		},

		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			maxWidth: 650,
			borderRadius: 8,
			padding: 30
		}
	}

	return (
		<Modal style={customStyles} isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Example Modal">
			<CloseIcon onClick={closeModal}>
				<Icon width="1.3rem" height="1.3rem" color="#1a202c" margin="0 0 0 5px" icon={faXmark} />
			</CloseIcon>
			<ModalContentWrapper>
				<ModalEmoji>{props.emoji}</ModalEmoji>
				<ModalTitle>{props.title}</ModalTitle>
				<ModalContent
					dangerouslySetInnerHTML={{
						__html: props.content
					}}
				/>
				<ButtonContainer>
					<ButtonComponent variant="primary" clickHandler={closeModal} text="Ik snap het" padding="10px 20px" />
					<ButtonComponent variant="secondary" clickHandler={redirectContact} text="Contact" padding="10px 20px" />
				</ButtonContainer>
			</ModalContentWrapper>
		</Modal>
	)
}

const ModalContentWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
`
const CloseIcon = styled.span`
	position: absolute;
	right: 15px;
	top: 15px;
	cursor: pointer;
`
const ModalEmoji = styled.span`
	font-size: 40px;
	margin-top: 20px;
`
const ModalTitle = styled.h2`
	margin: 30px 0;
	font-family: Poppins;
	font-weight: 400;
`
const ModalContent = styled.p`
	text-align: center;
	margin-bottom: 30px;
`

const ButtonContainer = styled.div`
	display: flex;
	width: 240px;
	margin-bottom: 20px;
	justify-content: space-between;
`

export default ModalComponent
